import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Card, Container } from "react-bootstrap"
import clsx from "clsx"
import "./home.scss"

const Home = () => {
  return (
    <Card
      className={clsx("image-card bg-dark text-white text-center")}
      id="home"
    >
      <StaticImage className="image" src="../images/home.webp" alt="home" />
      <Card.ImgOverlay className="no-padding">
        <Container>
          <div className="intro-text">
            <div className="intro-heading">
              お客様のご期待ご要望に
              <br />
              アプリを通じて応える！
            </div>
          </div>
        </Container>
      </Card.ImgOverlay>
    </Card>
  )
}

export default Home
