import * as React from "react"
import { Col, Container, ResponsiveEmbed, Row } from "react-bootstrap"

const Access = () => (
  <section id="アクセス" style={{ paddingTop: "100px", paddingBottom: "16px" }}>
    <h2 id="アクセス" className="h2 text-center mb-4">
      アクセス
    </h2>
    <Container>
      <Row>
        <Col sm={{ span: 6, offset: 1 }}>
          <ResponsiveEmbed aspectRatio="1by1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1623.1487916927379!2d139.4445629987878!3d35.54635209720946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018feb29555ffdb%3A0x5ef560285159efb3!2z5pel5pys44CB44CSMTk0LTAwMjEg5p2x5Lqs6YO955S655Sw5biC5Lit55S677yR5LiB55uu77yS4oiS77yV!5e0!3m2!1sja!2sus!4v1619559518002!5m2!1sja!2sus"
              width="600"
              height="450"
              loading="lazy"
            ></iframe>
          </ResponsiveEmbed>
        </Col>
        <Col sm={4}>
          <p>小田急線「町田」駅北口から徒歩4分</p>
          <p>JR横浜線「町田」駅北口から徒歩8分</p>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Access
