import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import * as Icon from "react-bootstrap-icons"

const Contact = () => (
  <section
    id="お問い合わせ"
    style={{ paddingTop: "100px", paddingBottom: "16px" }}
  >
    <h2 className="h2 text-center mb-4">お問い合わせ</h2>
    <Container>
      <Row className="justify-content-center">
        <Col xs={8} className="text-center">
          <p className="text-muted mb-5">
            お電話またはメールでお問い合わせください。
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="ml-auto text-center">
          <a className="d-block" href={`tel:042-816-2875`}>
            <Icon.Telephone size={96} className="text-primary mb-3" />
            <br />
            042-816-2875
          </a>
        </Col>
        <Col className="mr-auto text-center">
          <a className="d-block" href={`mailto:contact@appreply.co.jp`}>
            <Icon.Envelope size={96} className="text-primary mb-3" />
            <br />
            contact@appreply.co.jp
          </a>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Contact
