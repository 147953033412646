import * as React from "react"

import Seo from "../components/seo"
import Home from "./home"
import About from "./about"
import Access from "./access"
import Contact from "./contact"
import "../style/main.scss"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../components/header"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <Seo title="お客様のご期待ご要望にアプリを通じて応える！" lang="ja" />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <Home />
      <About />
      <Access />
      <Contact />
      <footer
        className="text-center mb-4"
        style={{
          marginTop: `300px`,
        }}
      >
        © {new Date().getFullYear()}, AppReply Co., Ltd. All rights reserved.
      </footer>
    </>
  )
}

export default IndexPage
