import * as React from "react"
import PropTypes from "prop-types"
import { Container, Nav, Navbar } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import useSmoothScrollTo from "../hooks/useSmoothScrollTo"
import useWindowOnScroll from "../hooks/useWindowOnScroll"
import clsx from "clsx"
import NavItem from "./NavItem"
import "./header.scss"
import { useStaticQuery, graphql } from "gatsby"

const Header = ({ siteTitle }) => {
  const handleScrollToTop = useSmoothScrollTo(0)

  const [expanded, setExpanded] = React.useState(false)
  const toggleMenu = React.useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])
  const closeMenu = React.useCallback(() => {
    setExpanded(false)
  }, [])
  const handleBrandClick = React.useCallback(() => {
    closeMenu()
    handleScrollToTop()
  }, [closeMenu, handleScrollToTop])

  const [shrink, setShrink] = React.useState(false)
  const handleWindowScroll = React.useCallback(() => {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop
    setShrink(scrollTop > 100)
  }, [])
  useWindowOnScroll(handleWindowScroll)
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo1-1.svg" }) {
        publicURL
      }
    }
  `)
  const { publicURL } = image.file
  return (
    <Navbar
      className={clsx("navbar-root", { "navbar-shrink": shrink })}
      expand="lg"
      fixed="top"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand className="cursor-pointer" onClick={handleBrandClick}>
          <img
            src={publicURL}
            alt="AppReply logo"
            className="d-inline-block align-top"
            height={25}
          />
        </Navbar.Brand>
        <Navbar.Toggle onClick={toggleMenu} aria-label="Toggle navigation" />
        <Navbar.Collapse>
          <Nav className="text-uppercase ml-auto">
            <NavItem key="info" to="会社概要" onClick={closeMenu} />
            <NavItem key="access" to="アクセス" onClick={closeMenu} />
            <NavItem key="contact" to="お問い合わせ" onClick={closeMenu} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
